@import './custom/acceptForm';
@import './custom/acceptTrackForm';
@import './custom/blockButton';
@import './custom/createDeliveryType';
@import './custom/createDirection';
@import './custom/createWebsite';
@import './custom/dashboard';
@import './custom/deleteConfirmation';
@import './custom/deliveryTypesItem';
@import './custom/deliveryTypeOne';
@import './custom/directions';
@import './custom/flagsDirectionBar';
@import './custom/formStyles';
@import './custom/inputTemplate';
@import './custom/itemSettings';
@import './custom/itemStep';
@import './custom/logoThumb';
@import './custom/modalNotification';
@import './custom/newGoods';
@import './custom/profilePage';
@import './custom/tracksFilter';
@import './custom/trackPage';
@import './custom/translations';
@import './custom/translationEditModalForm';
@import './custom/statusBar';
@import './custom/userDirectionsCell';

//

.stepper-item.CustomStepper {
  display: flex;
  flex-direction: row;
}

.flatpickr-calendar.multiMonth {
  width: auto !important;
}

.flatpickr-days,
.flatpickr-days .dayContainer {
  width: 100% !important;
  min-width: min-content !important;
  max-width: 100% !important;
}

*,
*::before,
*::after {
  // outline: 1px solid tomato;
}

button {
  box-sizing: content-box;
}

.row-reverse {
  flex-direction: row-reverse;
}

.form-check-input {
  flex-shrink: 0;
}
.inactiveCheckbox {
  color: grey;
}

.activeCheckbox {
  color: black;
}

.ql-container {
  font-size: 14px !important;
}

.ck.ck-editor__main > .ck-editor__editable{
  background-color: transparent !important;
}
.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content{
  border: none;
}

@include media-breakpoint-up(md) {
  .flex-md-gutter > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.flex-gutter > * {
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.flex-col-gutter > * {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.flex-col-rev-gutter > * {
  &:not(:first-child) {
    margin-bottom: 10px;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px 35px;
}

.grid-container label {
  width: 100%;
  box-sizing: border-box;
  margin-right: 25px;
}

.selectIndex {
  & * {
    z-index: 2;
  }
}

.PaginationDropdown > button {
  background-color: grey !important;
  padding: 5px 10px !important;
  font-size: 0.8rem;
}

.bg-active {
  background-color: $activeBg;
}

.bg-blocked {
  background-color: $blockedBg;
}

.error-border {
  border-color: var(--kt-danger) !important;
}

.print-hidden {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
.printContent {
  display: none;

  @media print {
    display: block;
  }
}

.accordion-button {
  width: 91.5%;
  border: 1px;
}

.ProfileOverview__delete-image {
  position: absolute;
  top: 10px;
  left: 100px;
  width: 20px !important;
  height: 25px !important;
  display: none !important;
}

.ProfileOverview__passport-image:hover .ProfileOverview__delete-image {
  display: block !important;
}

.splash-screen.PartialPageLoader {
  z-index: 100 !important;
}

.aside-dark .menu .menu-item .menu-section {
  color: $gray-300 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// bootstrap breakpoints

// // col-n
//  @media (max-width: 575px) {
// }
// // col-sm-n
// @media (min-width: 576px) {
// }

// // col-md
// @media (min-width: 768px) {
// }
// // col-lg-n
// @media (min-width: 992px) {
// }

// // col-xl-n
// @media (min-width: 1200px) {
// }

// // col-xxl-n
// @media (min-width: 1400px) {
// }

.phone-drop{
  max-height: 300px;
  .code-list{
    overflow-y: auto;
  }
}
